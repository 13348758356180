/*
 *  Flex Layout Specifics
*/

main {
  display: flex;
  flex-direction: row;
}

.round {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  list-style: none;
  padding: 0;
}

.round .spacer {
  flex-grow: 1;
}

.round .spacer:first-child, .round .spacer:last-child {
  flex-grow: .5;
}

.round .game-spacer {
  flex-grow: 1;
}

body {
  font-size: small;
  line-height: 1.4em;
}

.appBarButton {
  border-radius: 0%;
  margin: 0%;
  padding: 0%;
  border: 0px;
}

.appBarButton:not( :hover) {
  border-radius: 0%;
}

.appBarButton:hover {
  border-radius: 0%;
}

li.game {
  padding-left: 20px;
}

li.game.winner {
  font-weight: bold;
}

li.game span {
  float: right;
  margin-right: 5px;
}

li.game-top {
  border-bottom: 1px solid #aaa;
}

li.game-spacer {
  border-right: 1px solid #aaa;
  min-height: 40px;
}

li.game-bottom {
  border-top: 1px solid #aaa;
}